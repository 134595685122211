import type { FC, PropsWithChildren } from 'react';
import { useAuth, useAuthReload, useDocumentVHeight, useInitializeAxios, useInWebView, useJivoChat, useLocale, usePartnerParams, useScrollbarWidth, useServerDeviceId, useSsoLogin, useWebsocketUser } from '@mwl/core-lib';
import { PopupNotification } from '@/components/PopupNotification/PopupNotification';
import { base, supportedNotifications, widgetIds } from '@/constants';
import { useInitializeApp } from '@/hooks';
export const SetupProvider: FC<PropsWithChildren> = ({
  children
}) => {
  useInitializeAxios();
  useInitializeApp();
  useAuth();
  useLocale();
  usePartnerParams();
  useJivoChat({
    widgetIds
  });
  useInWebView();
  useSsoLogin();
  useAuthReload();
  useScrollbarWidth();
  useServerDeviceId();
  useDocumentVHeight();
  useWebsocketUser({
    NotificationComponent: PopupNotification,
    projectName: base.NAME_PROJECT,
    supportedNotifications
  });
  return <>{children}</>;
};