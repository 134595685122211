import { byKey, complex, simple } from '@mwl/marker-tree';

const registerPhoneFormSchema = complex({
  phoneInput: simple,
  passwordInput: simple,
  countrySelect: simple,
  signUpButton: simple,
  signInButton: simple,
  agreementCheckbox: simple,
});

const registerEmailFormSchema = complex({
  emailInput: simple,
  passwordInput: simple,
  countrySelect: simple,
  currencySelect: simple,
  signUpButton: simple,
  signInButton: simple,
  agreementCheckbox: simple,
});

const registerSocialFormSchema = complex({
  socialButtons: simple,
  currencySelect: simple,
  agreementCheckbox: simple,
});

export const registerSchema = complex({
  formPhone: registerPhoneFormSchema,
  formEmail: registerEmailFormSchema,
  formSocial: registerSocialFormSchema,
  tabs: byKey(simple),
});
