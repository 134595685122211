import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import type { TopMarketOutcomeEmptySB, TopMarketSB } from '@mwl/core-lib';
import { LONG_DASH } from '@mwl/core-lib';

import type { BalancingOutcome, OutcomeItem } from '@/types';

const tagsAliasesMapSport = {
  '1x2': ['1', 'x', '2'],
  total: ['over', 'title', 'under'],
  fora: ['1', 'title', '2'],
};

const tagsAliasesMapCyber = {
  '1x2': ['1', 'x', '2'],
};

export const useAliasedOutcomes = (markets?: Array<TopMarketSB>, type?: 'cyber'): Array<Array<OutcomeItem>> => {
  const { t } = useTranslation('sport');

  return useMemo(() => {
    const aliasesMap = type === 'cyber' ? tagsAliasesMapCyber : tagsAliasesMapSport;

    const outcomes = Object.entries(aliasesMap).map(([tag, aliases]) => {
      const market = markets?.find((item) => item.alias === tag);

      return aliases.map((alias, index) => {
        if (['fora', 'total'].includes(tag) && alias === 'title') {
          return {
            id: `${tag}_${index}`,
            isBalancing: true,
            title: t(`outcome_title.${tag}_${alias}`),
            value: market?.balancingSpecifier ?? LONG_DASH,
          } as BalancingOutcome;
        }

        const outcome = market?.outcomes.find((item) => item.alias === alias);

        if (!outcome) {
          return {
            id: `${tag}_${index}`,
            title: t(`outcome_title.${tag}_${alias}`),
            alias: 'empty',
          } as TopMarketOutcomeEmptySB;
        }

        return {
          ...outcome,
          title: t(`outcome_title.${tag}_${outcome?.alias}`),
        };
      });
    });

    return outcomes;
  }, [markets, t, type]);
};
