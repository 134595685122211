import type { ChangeEventHandler, FC } from 'react';
import { memo, useCallback, useEffect, useMemo } from 'react';
import type { UrlQuery } from '@mwl/core-lib';
import { extendAnalyticsData, getDomain, removeAllSpaces, sendAnalyticsData, useAuthFormOptions, useForm } from '@mwl/core-lib';
import { type SelectOnSelect, type SelectOption, FlagIcon } from '@mwl/ui';
import { Select } from '@/components/Select/Select';
import { SocialList } from '@/components/SocialList/SocialList';
import { useRegister } from '@/context';
import { useTogglePopupRegistrationBonus } from '@/hooks';
import { CheckboxSignUp } from '../CheckboxSignUp/CheckboxSignUp';
import { PromoCodeField } from '../PromoCodeField/PromoCodeField';
import type { FormRegistrationSnProps, FormRegistrationSnValues } from './FormRegistrationSn.types';
import { formSettings } from './FormRegistrationSn.utils';
import styles from './FormRegistrationSn.module.scss';
const domain = getDomain();
const BaseFormRegistrationSn: FC<FormRegistrationSnProps> = ({
  className,
  variant = 'default',
  parentRef,
  testSchema,
  analytics
}) => {
  const {
    changeField,
    formState
  } = useForm<FormRegistrationSnValues>(formSettings);
  const {
    currencies,
    userCountry,
    countries,
    defaultCountry
  } = useAuthFormOptions({
    FlagIcon
  });
  useTogglePopupRegistrationBonus({
    fieldsValues: [formState.values.promoCode]
  });
  const {
    updateCurrency,
    selectedBonus
  } = useRegister();
  const socialQuery: UrlQuery = useMemo(() => {
    const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || '';
    const query: UrlQuery = {
      domain,
      currency: formState.values.currency.label,
      timezone,
      ...(userCountry ? {
        country: userCountry
      } : {})
    };
    if (formState.values.promoCode) {
      query.promo_code = formState.values.promoCode;
    }
    if (selectedBonus.type && selectedBonus.type !== 'REFUSAL') {
      query.bonus_type = selectedBonus.type;
    }
    return query;
  }, [userCountry, formState.values.currency.label, formState.values.promoCode, selectedBonus]);
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    const {
      name,
      value,
      checked,
      type
    } = event.target;
    const typedName = (name as keyof FormRegistrationSnValues);
    const typedValue = ((type === 'checkbox' ? checked : removeAllSpaces(value)) as FormRegistrationSnValues[keyof FormRegistrationSnValues]);
    changeField(typedName, typedValue);
  }, [changeField]);
  const handleSelect: SelectOnSelect<SelectOption> = useCallback(option => {
    updateCurrency(option.value);
    changeField('currency', option);
  }, [changeField, updateCurrency]);
  useEffect(() => {
    if (currencies.length) {
      const country = countries.find(item => item.value === userCountry) ?? defaultCountry;
      const currencyValue = currencies.find(item => item.value === country?.currency) ?? currencies[0];
      handleSelect(currencyValue);
    }
  }, [countries, currencies, defaultCountry, handleSelect, userCountry]);
  const handleSelectCurrencyWithAnalytics = useCallback((option: SelectOption) => {
    const analyticsWithText = extendAnalyticsData(analytics?.currency, {
      text: option.label
    });
    sendAnalyticsData(analyticsWithText?.change?.eventName, analyticsWithText?.change?.data);
    handleSelect(option);
  }, [analytics?.currency, handleSelect]);
  const getCheckboxVariant = () => {
    if (!formState.isTouched) {
      return 'default';
    }
    return formState.errors?.agree ? 'red' : 'green';
  };
  return <form className={cn(styles.root, className, styles[`${variant}Variant`])} data-sentry-component="BaseFormRegistrationSn" data-sentry-source-file="FormRegistrationSn.tsx">
      <div className={cn(styles.formContainer)}>
        <Select selected={formState.values.currency} options={currencies} onSelect={handleSelectCurrencyWithAnalytics} className={styles.currencyField} parentRef={parentRef} size="sm" {...testSchema?.currencySelect.nodeProps} maxDesktopHeight={184} maxMobileHeight={130} data-sentry-element="Select" data-sentry-source-file="FormRegistrationSn.tsx" />
        <SocialList className={styles.socialList} query={socialQuery} disabled={!formState.values.agree} analytics={analytics?.social} {...testSchema?.socialButtons.nodeProps} data-sentry-element="SocialList" data-sentry-source-file="FormRegistrationSn.tsx" />
        <PromoCodeField name="promoCode" value={formState.values.promoCode} onChange={handleChange} size="sm" className={styles.promoCodeField} analytics={analytics?.promoCode} data-sentry-element="PromoCodeField" data-sentry-source-file="FormRegistrationSn.tsx" />
        <CheckboxSignUp name="agree" checked={formState.values.agree} onChange={handleChange} className={styles.agreeField} variant={getCheckboxVariant()} analytics={analytics?.rules} {...testSchema?.agreementCheckbox.nodeProps} data-sentry-element="CheckboxSignUp" data-sentry-source-file="FormRegistrationSn.tsx" />
      </div>
    </form>;
};
const FormRegistrationSn = memo(BaseFormRegistrationSn);
export * from './FormRegistrationSn.types';
export { FormRegistrationSn };