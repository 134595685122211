import type { FC } from 'react';
import { memo } from 'react';
import { handleEventWithAnalytics } from '@mwl/core-lib';
import { getTestProps } from '@mwl/marker-tree';
import { Switch as MwlSwitch } from '@mwl/ui';
import type { SwitchProps } from './Switch.types';
const BaseSwitch: FC<SwitchProps> = ({
  active,
  disabled,
  classes,
  onChange,
  analytics,
  ...props
}) => {
  const analyticsEvent = active ? analytics?.off : analytics?.on;
  const handleChange = (isChecked: boolean) => {
    if (disabled) {
      return;
    }
    const analyticsData = {
      ...(analyticsEvent?.data || {}),
      cls: cn('switch', classes?.root, {
        switch_disabled: disabled
      })
    };
    const eventHandler = handleEventWithAnalytics(onChange, analyticsEvent?.eventName, analyticsData);
    eventHandler(isChecked);
  };
  return <MwlSwitch
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wrapperProps={(getTestProps(props) as any)} // TODO(d.arutyunyan): fix later in @mwl/core-lib
  className={classes?.root} classes={{
    thumb: classes?.control,
    labelWrapper: classes?.label
  }} onChange={handleChange} checked={active} disabled={disabled} {...props} data-sentry-element="MwlSwitch" data-sentry-component="BaseSwitch" data-sentry-source-file="Switch.tsx" />;
};
const Switch = memo(BaseSwitch);
export * from './Switch.types';
export { Switch };