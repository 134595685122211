import { byKey, complex, simple } from '@mwl/marker-tree';

const localeSelectorSchema = complex({
  trigger: simple,
  value: simple,
  list: simple,
  item: simple,
});

const promoSectionSchema = complex({
  promoItem: byKey(simple),
});

export const userMenuSchema = complex({
  localeSelector: localeSelectorSchema,
  promoSection: promoSectionSchema,
  faqSection: simple,
  supportSection: simple,
  historySection: simple,
  casinoSection: simple,
  liveCasinoSection: simple,
  aviatorSection: simple,
  bonusBuySection: simple,
});
