import type { OutcomeItem } from '@/types';

const getOutcomeStatus = (outcome: OutcomeItem) => {
  if ('isBalancing' in outcome && outcome.isBalancing) {
    return false;
  }

  const isDone = 'status' in outcome && outcome?.status === 'CLOSED';
  const isEmpty = outcome && 'alias' in outcome && outcome?.alias === 'empty';
  const hasOutcome = !!outcome;

  return hasOutcome && !isDone && !isEmpty;
};

export const checkOutcomesGroups = (outcomesGroups: Array<Array<OutcomeItem>>) => {
  return outcomesGroups.every((outcomes) => !outcomes.some(getOutcomeStatus));
};
