import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { hideModal, readNotification, selectActiveModalId } from '@mwl/core-lib';
import type { ButtonProps } from '@/components/Button/Button';
import { Button } from '@/components/Button/Button';
import { Image } from '@/components/Image/Image';
import { useMediaWidthDown, useTypedSelector } from '@/hooks';
import { getButtonAnalytics } from './PopupNotification.analytics';
import { usePopupData } from './PopupNotification.hooks';
import type { PopupNotificationProps } from './PopupNotification.types';
import { getPopupClassName } from './PopupNotification.utils';
import styles from './PopupNotification.module.scss';
const BasePopupNotification: FC<PopupNotificationProps> = ({
  type,
  data
}) => {
  const {
    t
  } = useTranslation('notification');
  const dispatch = useDispatch();
  const activeModalId = useTypedSelector(selectActiveModalId);
  const isMobile = useMediaWidthDown('screenXs');
  const {
    translationKeys
  } = data.payload;
  const handleClose = () => {
    if (activeModalId) {
      dispatch(hideModal({
        id: activeModalId,
        type
      }));
    }
  };
  const {
    bonus,
    popupType,
    header,
    content
  } = usePopupData({
    data,
    type
  });
  const buttonText = t(translationKeys?.button || '', 'Detailed');
  const buttonProps: ButtonProps = popupType?.href ? {
    as: 'link',
    href: popupType.href
  } : {
    as: 'button'
  };
  const imageSrc = isMobile && popupType?.mobileImg ? popupType.mobileImg : popupType?.img;
  const className = getPopupClassName(type);
  const buttonAnalytics = getButtonAnalytics({
    type,
    payload: data.payload,
    url: popupType?.href,
    text: buttonText
  });
  useEffect(() => {
    if (type !== 'LOYALTY_LUCK') {
      readNotification(data.innerId);
    }
  }, [data.innerId, type]);
  if (!popupType) {
    return null;
  }
  return <div className={cn(styles.root, className)} data-sentry-component="BasePopupNotification" data-sentry-source-file="PopupNotification.tsx">
      <div className={styles.imageContainer}>
        <div className={cn(styles.imageWrapper, popupType.imgClass)}>
          {imageSrc && <Image src={imageSrc} style={{
          objectFit: 'contain'
        }} fill alt="Prize" className={styles.image} />}
        </div>
      </div>
      <div>
        <h2 className={styles.header}>{header}</h2>
        {bonus && <div className={styles.bonus}>{bonus}</div>}
      </div>
      {content}
      <Button {...buttonProps} onClick={handleClose} className={styles.button} fontWeight="bold" fontSize="lg" size="md" color="red" rounded analytics={buttonAnalytics} data-sentry-element="Button" data-sentry-source-file="PopupNotification.tsx">
        {buttonText}
      </Button>
    </div>;
};
const PopupNotification = memo(BasePopupNotification);
export { PopupNotification };