import { useRouter } from 'next/router';

import { routes } from '@/constants';

/**
 * Хук возвращает true если текущая страница - любая страница спорта
 */
export const useIsSportPage = (): boolean => {
  const { pathname } = useRouter();
  return pathname.startsWith(routes.sport.home);
};
