import { byKey, complex, simple } from '@mwl/marker-tree';

import { registerSchema } from '../register';

const bannerSchema = complex({
  wrapper: simple,
  slide: byKey(simple),
  register: registerSchema,
});

export const casinoLayoutSchema = complex({
  banner: bannerSchema,
  winnersList: simple,
});
