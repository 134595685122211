import { byKey, complex, simple } from '@mwl/marker-tree';

const paymentSchema = complex({
  cards: byKey(simple),
  formWrapper: byKey(simple),
  useBonusPacketsSwitch: simple,
  bonusPackets: byKey(simple),
  bonusPacketsSlider: simple,
});

export const depositPopupSchema = complex({
  tabs: byKey(simple),
  payment: paymentSchema,
});
