import { complex, simple } from '@mwl/marker-tree';

const sideSectionSchema = complex({
  winnersList: simple,
});

const headerSchema = complex({
  container: simple,
  mode: simple,
  close: simple,
  favorite: simple,
  fullscreen: simple,
});

const breadcrumbsSchema = complex({
  section: simple,
  provider: simple,
});

export const casinoGamePageSchema = complex({
  sideSection: sideSectionSchema,
  header: headerSchema,
  frame: simple,
  breadcrumbs: breadcrumbsSchema,
});
