import { byKey, complex, simple } from '@mwl/marker-tree';

const downloadAndroidSchema = complex({
  button: simple,
  icon: simple,
});

const downloadButtonSchema = complex({
  ios: simple,
  android: downloadAndroidSchema,
});

const accountSchema = complex({
  amountButton: simple,
  signInButton: simple,
  signUpButton: simple,
  balance: simple,
});

const langSchema = complex({
  trigger: simple,
  value: simple,
  list: simple,
  item: simple,
});

const timezoneSchema = complex({
  trigger: simple,
  list: simple,
  item: simple,
});

const settingsSchema = complex({
  time: timezoneSchema,
  lang: langSchema,
});

export const headerSchema = complex({
  logo: simple,
  banner: simple,
  wheel: simple,
  promo: simple,
  settings: settingsSchema,
  account: accountSchema,
  menuButton: simple,
  refill: simple,
  aviatorButton: accountSchema,
  navItem: byKey(simple),
  downloadButton: downloadButtonSchema,
});
