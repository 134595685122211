import { byKey, complex, simple } from '@mwl/marker-tree';

const bonusItemSchema = complex({
  amountText: simple,
  expiresText: simple,
  infoButton: simple,
  cancelButton: simple,
  cancelModal: simple,
  infoModal: simple,
});

const bonusListSchema = complex({
  header: simple,
  bonusItem: byKey(bonusItemSchema),
});

const tournamentItemSchema = complex({
  infoButton: simple,
  infoModal: simple,
});

const torunamentsListSchema = complex({
  tournamentBanner: byKey(tournamentItemSchema),
});

const gameCardSchema = complex({
  play: simple,
  demo: simple,
  favorites: simple,
  provider: simple,
});

const gamesGridSchema = complex({
  container: simple,
  loadMore: simple,
  games: byKey(simple),
});

const gamesGridLayoutSchema = complex({
  title: simple,
  allButton: simple,
});

export const commonSchema = complex({
  sectionGamesButton: simple,
  gameCard: byKey(gameCardSchema),
  gamesGrid: gamesGridSchema,
  gamesGridLayout: gamesGridLayoutSchema,
  bonusList: bonusListSchema,
  tournamentsList: torunamentsListSchema,
});
