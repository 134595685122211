import type { Analytics } from '@mwl/core-lib';
import { extendAnalyticsData, sendAnalyticsData } from '@mwl/core-lib';

import type { FormRecoveryAnalytics, FormRecoveryAnalyticsParams } from './FormRecovery.types';

export const useFormRecoveryAnalytics = ({
  analytics,
  submitButtonText,
  cancelButtonText,
}: FormRecoveryAnalyticsParams): FormRecoveryAnalytics => {
  const submitAnalytics = extendAnalyticsData({ click: analytics?.submit }, { text: submitButtonText });
  const cancelAnalytics = extendAnalyticsData({ click: analytics?.close }, { text: cancelButtonText });

  const sendAnalyticsOnBlur = () => {
    sendAnalyticsData(analytics?.change?.eventName, analytics?.change?.data);
  };

  const sendAnalyticsOnFieldBlur = (fieldAnalytics?: Analytics) => () => {
    sendAnalyticsData(fieldAnalytics?.change?.eventName, fieldAnalytics?.change?.data);
  };

  const sendAnalyticsOnSuccess = () => {
    sendAnalyticsData(analytics?.success?.eventName, analytics?.success?.data);
  };

  const sendAnalyticsOnError = () => {
    sendAnalyticsData(analytics?.error?.eventName, analytics?.error?.data);
  };

  return {
    submitAnalytics,
    cancelAnalytics,
    sendAnalyticsOnBlur,
    sendAnalyticsOnSuccess,
    sendAnalyticsOnError,
    sendAnalyticsOnFieldBlur,
  };
};
