import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import type { ParsedUrlQueryInput } from 'querystring';
import type { UrlObject } from 'url';
import { selectSports } from '@mwl/core-lib';

import { routes } from '@/constants';

import type { SportUrlParams } from '@/types';

import { useTypedSelector } from '../useTypedSelector';

import type {
  CheckIsSportMenuActive,
  CreateChampionshipLink,
  CreateSportCategoryLink,
  CreateSportItemLink,
  CreateSportItemLinkOptions,
  CreateStageLink,
  CreateUpcomingLink,
  SetFilters,
  UseSportUrlParamsResult,
} from './useSportUrlParams.types';
import {
  convertParamsToNormal,
  convertParamsToShort,
  convertToArray,
  getLinesCountByEntity,
  removeEmptyValues,
} from './useSportUrlParams.utils';

const defaultCreateSportItemLinkOptions: CreateSportItemLinkOptions = {
  isMulti: false,
};

/** Хук для работы с URL параметрами на странице спорта */
export const useSportUrlParams = (): UseSportUrlParamsResult => {
  const router = useRouter();

  const sportsList = useTypedSelector(selectSports);

  const query = useMemo<SportUrlParams>(
    () => ({
      ...router.query,
      cts: convertToArray(router.query.cts),
      sps: router.query.sps !== 'all' ? convertToArray(router.query.sps) : router.query.sps,
      chs: convertToArray(router.query.chs),
    }),
    [router.query],
  );

  /** Создание ссылки для вида спорта */
  const createSportItemLink = useCallback<CreateSportItemLink>(
    (sportId, options = defaultCreateSportItemLinkOptions) => {
      const { st, sp, sps } = query;
      const { isMulti } = options;

      let newQuery: Partial<SportUrlParams> = { st };

      if (isMulti) {
        const sportIdsArray = Array.isArray(sps) ? sps : [];

        const updatedSportIds = sportIdsArray.filter((id) => id !== sportId);

        if (updatedSportIds.length === sportIdsArray.length) {
          // Если элемент не был найден, добавить его
          updatedSportIds.push(sportId);
        }

        newQuery = {
          ...newQuery,
          sps: updatedSportIds,
        };
      } else {
        newQuery = sp === sportId ? { ...newQuery } : { ...newQuery, sp: sportId };
      }

      return { pathname: routes.sport.home, query: removeEmptyValues(newQuery) };
    },
    [query],
  );

  /** Создание ссылки для категории */
  const createSportCategoryLink = useCallback<CreateSportCategoryLink>(
    (category, isInSearch) => {
      if (!category) {
        return { query: removeEmptyValues(query as ParsedUrlQueryInput) };
      }

      const { ct, ch: _, sch: __, schsp: ___, ...otherQuery } = query;

      let newQuery: SportUrlParams = { ...otherQuery, ct: String(category.id) };

      if (isInSearch) {
        newQuery = { ct: String(category.id) };
      } else if (String(category.id) === ct) {
        newQuery = otherQuery;
      }

      return { query: removeEmptyValues(newQuery as ParsedUrlQueryInput) };
    },
    [query],
  );

  /** Создание ссылки для чемпионата */
  const createChampionshipLink = useCallback<CreateChampionshipLink>(
    (championship, isInSearch) => {
      if (!championship) {
        return { query: removeEmptyValues(query as ParsedUrlQueryInput) };
      }

      const { ch, sch: _, schsp: __, ...otherQuery } = query;

      let newQuery: SportUrlParams = { ...otherQuery, ch: String(championship.id) };

      if (isInSearch) {
        newQuery = { ch: String(championship.id) };
      } else if (String(championship.id) === ch) {
        newQuery = otherQuery;
      }

      return { query: removeEmptyValues(newQuery as ParsedUrlQueryInput) };
    },
    [query],
  );

  /** Создание ссылки для фильтра "Upcoming" */
  const createUpcomingLink = useCallback<CreateUpcomingLink>(
    (newUpcoming) => {
      const { upc, ...otherQuery } = query;
      const newQuery = upc === newUpcoming ? otherQuery : { ...otherQuery, upc: newUpcoming };

      return { query: removeEmptyValues(newQuery) };
    },
    [query],
  );

  /** Создание ссылки для фильтра LIVE / PREGAME */
  const createStageLink = useCallback<CreateStageLink>(
    (newStage) => {
      const { st, sp, sps, ...otherQuery } = query;

      const linesCountBySports = getLinesCountByEntity({
        list: sportsList,
        targets: ([] as Array<string>).concat(sps ?? [], sp ?? []),
        newStage,
      });

      const newSport = sp && linesCountBySports[sp] ? sp : undefined;
      const newSports = typeof sps === 'string' ? sps : (sps ?? []).filter((id) => linesCountBySports[id]);

      const mainQuery = { ...otherQuery, sp: newSport, sps: newSports };
      const newQuery = st === newStage ? mainQuery : { ...mainQuery, st: newStage };

      return { pathname: routes.sport.home, query: removeEmptyValues(newQuery) };
    },
    [query, sportsList],
  );

  /** Проверки для подсветки меню навигации на страницах спорта */
  const checkIsSportMenuActive: CheckIsSportMenuActive = (url, strictlyHighlightedOnUrl) => {
    if (strictlyHighlightedOnUrl && router.asPath.includes(strictlyHighlightedOnUrl)) {
      return true;
    }

    const { st } = query;

    if (!st && typeof url === 'string' && url && router.asPath === url) {
      return true;
    }

    if (st && typeof url === 'object' && typeof url.query === 'object' && url.query?.st === st) {
      return true;
    }

    return false;
  };

  const patchParams = useCallback(
    (params: Partial<SportUrlParams>): UrlObject['query'] => {
      return removeEmptyValues({ ...query, ...params });
    },
    [query],
  );

  const setFilters = useCallback<SetFilters>(
    (values, options) => {
      const shortValues = convertParamsToShort(values);
      const routerQuery = { query: patchParams(shortValues) };
      const routerOptions = { shallow: true, scroll: false };

      if (options?.isRouterPush) {
        return router.push(routerQuery, undefined, routerOptions);
      }

      return router.replace(routerQuery, undefined, routerOptions);
    },
    [patchParams, router],
  );

  const hasFilters = Boolean(
    query.sp || query.sps || query.cts || query.ct || query.ch || query.chs || query.st || query.upc,
  );

  return {
    ...convertParamsToNormal(query),
    createSportItemLink,
    createChampionshipLink,
    createSportCategoryLink,
    createUpcomingLink,
    createStageLink,
    checkIsSportMenuActive,
    setFilters,
    hasFilters,
    patchParams,
  };
};
