import type { FC, PropsWithChildren } from 'react';
import { useCallback } from 'react';
import Link from 'next/link';
import type { Analytics } from '@mwl/core-lib';
import { extendAnalyticsData } from '@mwl/core-lib';
import { Button } from '@/components/Button/Button';
import { getCompetitorsLineName } from '@/utils';
import type { LineWithEntities } from '@/types';
import { useSportUrlParams } from './useSportUrlParams/useSportUrlParams';
interface UseSportLinksParams {
  data: LineWithEntities;
  styles: Readonly<Dictionary<string>>;
  analytics?: {
    sportLink?: Analytics;
    championShipLink?: Analytics;
    categoryLink?: Analytics;
  };
  isInSearch?: boolean;
}

/** Хук для генерации ссылок на разделы спорта
 * @param data - Данные линии
 * @param styles - Объект стилей для компонентов ссылок
 * @param isInSearch - Флаг, указывающий, находится ли пользователь в разделе поиска
 * @param analytics - Объект с данными для аналитики
 *
 * @returns SportLink - Компонент ссылки на вид спорта
 * @returns championshipLink - Компонент ссылки на чемпионат и / или категорию
 * @returns sportCode - Код спорта
 * @returns sportIcon - Иконка спорта
 */
export const useSportLinks = ({
  data,
  styles,
  analytics,
  isInSearch
}: UseSportLinksParams) => {
  const {
    createSportItemLink,
    createChampionshipLink,
    createSportCategoryLink,
    championshipId,
    categoryId,
    sportId
  } = useSportUrlParams();
  const {
    championship,
    category,
    sport
  } = data;
  const championshipUrl = createChampionshipLink(championship, isInSearch);
  let championshipLink = championship ? <Link className={styles.headerLink} title={championship?.title} href={championshipUrl}>
      {championship.title}
    </Link> : '';
  if (String(championship?.id) === championshipId) {
    championshipLink = <span title={championship?.title}>{championship?.title}</span>;
  }
  const categoryTitle = category?.title ?? '';
  const hasCategoryTitle = categoryTitle && championship?.title?.includes(categoryTitle);
  if (!hasCategoryTitle) {
    const categoryUrl = createSportCategoryLink(category, isInSearch);
    const text = getCompetitorsLineName(data);
    const categoryNameComponent = String(category?.id) === categoryId ? <span title={categoryTitle}>{categoryTitle}.</span> : <Button as="link" href={categoryUrl} className={styles.headerLink} variant="text" title={categoryTitle} analytics={analytics?.categoryLink ? extendAnalyticsData(analytics?.categoryLink, {
      href: categoryUrl,
      text,
      category: categoryTitle
    }) : undefined}>
          {categoryTitle}.
        </Button>;
    const championshipNameComponent = String(championship?.id) === championshipId ? <span title={championship?.title}>{championship?.title ?? ''}</span> : <Button as="link" href={championshipUrl} className={styles.headerLink} variant="text" title={championship?.title} analytics={extendAnalyticsData(analytics?.championShipLink, {
      href: championshipUrl,
      text,
      category: categoryTitle,
      championships: championship.title
    })}>
          {championship?.title ?? ''}
        </Button>;
    championshipLink = <>
        {categoryNameComponent} {championshipNameComponent}
      </>;
  }
  const SportLink: FC<PropsWithChildren> = useCallback(({
    children
  }) => {
    if (sportId === String(sport.id)) {
      return <span className={styles.sportLink}>{children}</span>;
    }
    const href = createSportItemLink(String(sport.id));
    return <Button as="link" href={href} className={styles.sportLink} variant="text" analytics={analytics?.sportLink ? extendAnalyticsData(analytics?.sportLink, {
      href
    }) : undefined}>
          {children}
        </Button>;
  }, [sportId, sport.id, createSportItemLink, styles.sportLink, analytics?.sportLink]);
  return {
    SportLink,
    championshipLink,
    sportCode: sport.code,
    sportIcon: sport.icon
  };
};