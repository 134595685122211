import type { SupportedPopupNotificationsType } from '@mwl/core-lib';

export const supportedNotifications: SupportedPopupNotificationsType = {
  RATING_POINTS: true,
  SILVER_RANG_REWARD: true,
  SILVER_RANG: true,
  GOLD_RANG_REWARD: true,
  GOLD_RANG: true,
  LOYALTY_LEVEL_RANG_REWARD: true,
  SILVER_RANG_LOSS: true,
  GOLD_RANG_LOSS: true,
  CASHBACK_CASINO_BUTTON: true,
  CASHBACK_CASINO_SUCCESS: true,
  LOYALTY_SHOP: true,
  LOYALTY_LUCK: true,
  LOYALTY_FREESPINS: true,
  NO_COINS: true,
  BONUS_CASINO: false,
  BONUS_PACKET_FIRSTDEP: false,
  BONUS_SPORT: false,
  COINS: false,
  FREESPIN: false,
  LOYALTY_RANG: false,
  LOYALTY_RANG_REWARD: false,
  NOTIFICATION_MBC_REFILL: false,
  ROLLED_BONUS_CASINO: false,
  ROLLED_BONUS_SPORT: false,
  FREEBET: false,
};
