import type { Analytics, PopupNotificationType, WsPopupMessagePayload } from '@mwl/core-lib';

import type { GetButtonAnalyticsParams } from './PopupNotification.types';

export const popupNames: Partial<Record<PopupNotificationType, string>> = {
  RATING_POINTS: 'ratingPoints',
  SILVER_RANG_REWARD: 'silverRangReward',
  SILVER_RANG: 'silverRang',
  GOLD_RANG_REWARD: 'goldRangReward',
  GOLD_RANG: 'goldRang',
  LOYALTY_LEVEL_RANG_REWARD: 'loyaltyLevelRangReward',
  SILVER_RANG_LOSS: 'silverRangLoss',
  GOLD_RANG_LOSS: 'goldRangLoss',
  CASHBACK_CASINO_BUTTON: 'cashbackCasinoButton',
  CASHBACK_CASINO_SUCCESS: 'cashbackCasinoSuccess',
  LOYALTY_SHOP: 'loyaltyShop',
  LOYALTY_LUCK: 'loyaltyLuck',
  LOYALTY_FREESPINS: 'loyaltyFreespins',
};

const getDataAnalytics = ({ type, text, url, payload }: GetButtonAnalyticsParams) => {
  const popupName = type && popupNames[type];

  if (!popupName) {
    return undefined;
  }

  let expireDate;

  if (payload?.expirationDays && ['SILVER_RANG_LOSS', 'GOLD_RANG_LOSS'].includes(type)) {
    expireDate = `${payload.expirationDays}`;
  }

  return {
    element_type: 'popup',
    popup_name: popupName,
    ...(expireDate && { expire_date: expireDate }),
    ...(payload?.amount && { amount: payload.amount }),
    ...(text && { text }),
    ...(url && { url }),
  };
};

export const getPopupNotificationAnalytics = (
  type?: PopupNotificationType,
  payload?: WsPopupMessagePayload,
): Analytics | undefined => {
  const data = getDataAnalytics({ type, payload });

  if (!data) {
    return undefined;
  }

  return {
    open: {
      eventName: 'popup_open',
      data,
    },
    closeIconClick: {
      eventName: 'popup_xIcon_close',
      data,
    },
    outsideClick: {
      eventName: 'popup_outsideClick_close',
      data,
    },
  };
};

export const getButtonAnalytics = ({ type, text, url, payload }: GetButtonAnalyticsParams): Analytics | undefined => {
  let eventName: string | undefined;

  switch (type) {
    case 'RATING_POINTS':
    case 'CASHBACK_CASINO_SUCCESS':
      eventName = 'popup_detailed_btn_click';
      break;
    case 'SILVER_RANG_REWARD':
    case 'SILVER_RANG':
    case 'GOLD_RANG_REWARD':
    case 'GOLD_RANG':
    case 'SILVER_RANG_LOSS':
    case 'GOLD_RANG_LOSS':
    case 'LOYALTY_FREESPINS':
      eventName = 'popup_ok_btn_click';
      break;
    case 'LOYALTY_LEVEL_RANG_REWARD':
    case 'CASHBACK_CASINO_BUTTON':
      eventName = 'popup_received_btn_click';
      break;
    case 'LOYALTY_SHOP':
      eventName = 'popup_toStore_btn_click';
      break;
    case 'LOYALTY_LUCK':
      eventName = 'popup_play_btn_click';
      break;
    default:
      eventName = undefined;
  }

  const data = getDataAnalytics({ type, text, url, payload });

  if (!eventName || !data) {
    return undefined;
  }

  return {
    click: {
      eventName,
      data,
    },
  };
};
