import { useMemo } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { selectUserBalanceCurrencySymbol } from '@mwl/core-lib';
import { useTypedSelector } from '@/hooks';
import type { UsePopupDataParams } from './PopupNotification.types';
import { getBonus, getPopupType } from './PopupNotification.utils';
import styles from './PopupNotification.module.scss';
export const usePopupData = ({
  data,
  type
}: UsePopupDataParams) => {
  const {
    t
  } = useTranslation('notification');
  const currencySymbol = useTypedSelector(selectUserBalanceCurrencySymbol);
  const popupType = getPopupType(type);
  const bonus = getBonus({
    data,
    type,
    currencySymbol
  });
  const {
    translationKeys,
    expirationDays,
    debt_compensation_amount
  } = data.payload;
  const content = useMemo(() => {
    if (!translationKeys?.content) {
      return null;
    }
    switch (type) {
      case 'SILVER_RANG_LOSS':
      case 'GOLD_RANG_LOSS':
        return <p className={styles.message}>{t(translationKeys.content, {
            expirationDays
          })}</p>;
      case 'NO_COINS':
        return <p className={styles.message}>
            <Trans ns="notification" components={[<span key={0} className={cn(styles.redText, styles.bold)} />]} i18nKey={translationKeys.content} values={{
            debt_compensation_amount
          }} />
          </p>;
      default:
        return <p className={styles.message}>{t(translationKeys.content)}</p>;
    }
  }, [debt_compensation_amount, expirationDays, t, translationKeys?.content, type]);
  const header = useMemo(() => {
    if (!translationKeys?.header) {
      return '';
    }
    switch (type) {
      case 'LOYALTY_FREESPINS':
        return <Trans ns="notification" components={[<span key={0} className={styles.redText} />]} i18nKey={translationKeys.header} />;
      default:
        return t(translationKeys.header);
    }
  }, [t, translationKeys?.header, type]);
  return {
    popupType,
    bonus,
    content,
    header
  };
};