import type { PopupNotificationType } from '@mwl/core-lib';

import CashbackImage from '@public/assets/notification/images/cashback.png';
import CashbackMobileImage from '@public/assets/notification/images/cashbackMob.png';
import CashbackSuccessImage from '@public/assets/notification/images/cashbackSuccess.png';
import ExperiencePointsImage from '@public/assets/notification/images/experiencePoints.png';
import ExperiencePointsMobileImage from '@public/assets/notification/images/experiencePointsMob.png';
import GemsBoxImage from '@public/assets/notification/images/gemsBox.png';
import GoldRankImage from '@public/assets/notification/images/goldRank.png';
import GoldRankLossImage from '@public/assets/notification/images/goldRankLoss.png';
import LevelUpImage from '@public/assets/notification/images/levelUp.png';
import LevelUpMobileImage from '@public/assets/notification/images/levelUpMob.png';
import LoyaltyFreespinImage from '@public/assets/notification/images/loyaltyFreespin.png';
import LoyaltyLuckImage from '@public/assets/notification/images/loyaltyLuck.png';
import LoyaltyLuckMobileImage from '@public/assets/notification/images/loyaltyLuckMob.png';
import LoyaltyShopImage from '@public/assets/notification/images/loyaltyShop.png';
import SilverRankImage from '@public/assets/notification/images/silverRank.png';
import SilverRankLossImage from '@public/assets/notification/images/silverRankLoss.png';

import { routes } from '@/constants';

import type { GetBonusParams, PopupType } from './PopupNotification.types';

import styles from './PopupNotification.module.scss';

export const getPopupType = (type: PopupNotificationType): PopupType | null => {
  switch (type) {
    case 'RATING_POINTS':
      return {
        img: ExperiencePointsImage,
        mobileImg: ExperiencePointsMobileImage,
        imgClass: styles.experienceImage,
      };
    case 'SILVER_RANG_REWARD':
    case 'SILVER_RANG':
      return {
        img: SilverRankImage,
      };
    case 'GOLD_RANG_REWARD':
    case 'GOLD_RANG':
      return {
        img: GoldRankImage,
      };
    case 'LOYALTY_LEVEL_RANG_REWARD':
      return {
        img: LevelUpImage,
        mobileImg: LevelUpMobileImage,
        imgClass: styles.levelUpImage,
      };
    case 'SILVER_RANG_LOSS':
      return {
        img: SilverRankLossImage,
      };
    case 'GOLD_RANG_LOSS':
      return {
        img: GoldRankLossImage,
      };
    case 'CASHBACK_CASINO_BUTTON':
      return {
        img: CashbackImage,
        mobileImg: CashbackMobileImage,
        imgClass: styles.cashbackImage,
        href: routes.loyalty,
      };
    case 'CASHBACK_CASINO_SUCCESS':
      return {
        img: CashbackSuccessImage,
        href: routes.casino.home,
      };
    case 'LOYALTY_SHOP':
      return {
        img: LoyaltyShopImage,
        href: routes.store,
      };
    case 'LOYALTY_LUCK':
      return {
        img: LoyaltyLuckImage,
        mobileImg: LoyaltyLuckMobileImage,
        href: routes.home,
      };
    case 'LOYALTY_FREESPINS':
      return {
        img: LoyaltyFreespinImage,
      };
    case 'NO_COINS':
      return {
        img: GemsBoxImage,
      };

    default:
      return null;
  }
};

export const getBonus = ({ data, type, currencySymbol = '' }: GetBonusParams): string => {
  let bonus = '';

  const { payload } = data;

  if (!payload || type === 'CASHBACK_CASINO_BUTTON') {
    return bonus;
  }

  if (payload.increase_amount && type === 'NO_COINS') {
    bonus = `${payload.increase_amount}`;
  } else if (payload.amount && type === 'CASHBACK_CASINO_SUCCESS') {
    bonus = `${currencySymbol} ${payload.amount}`;
  } else if (payload.amount) {
    bonus = `${payload.amount}`;
  }

  return bonus;
};

export const getPopupClassName = (type: PopupNotificationType): string | undefined => {
  switch (type) {
    case 'CASHBACK_CASINO_SUCCESS':
      return styles.medium;
    case 'LOYALTY_FREESPINS':
      return styles.small;
    default:
      return undefined;
  }
};
