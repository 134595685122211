import { complex, simple } from '@mwl/marker-tree';

import { authPopupSchema } from '../authPopup';
import { depositPopupSchema } from '../depositPopup';

export const globalPopupsSchema = complex({
  auth: authPopupSchema,
  deposit: depositPopupSchema,
  bonus: simple,
});
