import type { ChangeEventHandler, FC } from 'react';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { loginSuccess, removeAllSpaces, resetPasswordByCodeId, useForm, useSubmitFormAuth } from '@mwl/core-lib';
import { Button } from '@/components/Button/Button';
import { FormFieldHidden } from '@/components/FormFieldHidden/FormFieldHidden';
import { FormMessage } from '@/components/FormMessage/FormMessage';
import { Loader } from '@/components/Loader/Loader';
import { useTypedDispatch } from '@/hooks';
import { formRecoveryPasswordObject } from '@/utils';
import { useFormRecoveryAnalytics } from '../../FormRecovery.analytics.hooks';
import { useRecoveryState } from '../../FormRecovery.context';
import type { FormRecoveryPasswordProps, FormRecoveryPasswordValues } from './FormRecoveryPassword.types';
import { formSettings } from './FormRecoveryPassword.utils';
import styles from './FormRecoveryPassword.module.scss';
const BaseFormRecoveryPassword: FC<FormRecoveryPasswordProps> = ({
  className,
  analytics
}) => {
  const {
    t
  } = useTranslation('common');
  const dispatch = useTypedDispatch();
  const {
    codeId
  } = useRecoveryState();
  const {
    changeField,
    formState,
    handleSubmit
  } = useForm<FormRecoveryPasswordValues>(formSettings);
  const submitButtonText = t('recovery.button.save', 'Save');
  const {
    submitAnalytics,
    sendAnalyticsOnSuccess,
    sendAnalyticsOnError,
    sendAnalyticsOnFieldBlur
  } = useFormRecoveryAnalytics({
    analytics: analytics?.form,
    submitButtonText
  });
  const {
    state: {
      error,
      isLoading
    },
    onSubmitConfirm
  } = useSubmitFormAuth({
    requestHandler: resetPasswordByCodeId,
    formState: formState.values,
    onSuccess: data => {
      if (data?.token) {
        sendAnalyticsOnSuccess();
        dispatch(loginSuccess({
          token: data.token
        }));
      }
    }
  });
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    const {
      name,
      value
    } = event.target;
    changeField((name as keyof FormRecoveryPasswordValues), removeAllSpaces(value));
  }, [changeField]);
  const submitConfirm = useCallback(async (value: FormRecoveryPasswordValues) => {
    onSubmitConfirm({
      codeId,
      password: value.noSearchNewPassword
    });
  }, [codeId, onSubmitConfirm]);
  const errorText = useMemo(() => {
    let errorMessage;
    if (error) {
      errorMessage = Array.isArray(error?.message) ? t(error.message) : error.message;
    }
    if (formState.isTouched) {
      if (formState.errors?.noSearchNewPassword) {
        errorMessage = t(formState.errors.noSearchNewPassword);
      } else if (formState.errors?.noSearchConfirmNewPassword) {
        errorMessage = t(formState.errors.noSearchConfirmNewPassword);
      }
    }
    if (errorMessage) {
      return <FormMessage>{errorMessage}</FormMessage>;
    }
    return null;
  }, [error, formState.errors.noSearchConfirmNewPassword, formState.errors.noSearchNewPassword, formState.isTouched, t]);
  useEffect(() => {
    if (errorText) {
      sendAnalyticsOnError();
    }
  }, [errorText, sendAnalyticsOnError]);
  return <form {...formRecoveryPasswordObject.nodeProps} className={cn(styles.root, className)} onSubmit={handleSubmit(submitConfirm)} data-sentry-component="BaseFormRecoveryPassword" data-sentry-source-file="FormRecoveryPassword.tsx">
      {errorText}
      {isLoading && <Loader />}
      <FormFieldHidden {...formRecoveryPasswordObject.newPasswordField.nodeProps} placeholder={t('form.field.new_password', 'New password')} value={formState.values.noSearchNewPassword} onChange={handleChange} onBlur={sendAnalyticsOnFieldBlur(analytics?.newPassword)} name="noSearchNewPassword" className={styles.field} isError={Boolean(formState.isTouched && formState.errors?.noSearchNewPassword)} analytics={analytics?.newPassword} data-sentry-element="FormFieldHidden" data-sentry-source-file="FormRecoveryPassword.tsx" />
      <FormFieldHidden {...formRecoveryPasswordObject.confirmPasswordField.nodeProps} placeholder={t('form.field.confirm_password', 'Confirm password')} value={formState.values.noSearchConfirmNewPassword} onChange={handleChange} onBlur={sendAnalyticsOnFieldBlur(analytics?.confirmPassword)} name="noSearchConfirmNewPassword" className={styles.field} isError={Boolean(formState.isTouched && formState.errors?.noSearchConfirmNewPassword)} analytics={analytics?.confirmPassword} data-sentry-element="FormFieldHidden" data-sentry-source-file="FormRecoveryPassword.tsx" />
      <Button {...formRecoveryPasswordObject.submitButton.nodeProps} as="button" variant="fulfilled" color="red" fontSize="xl" fontWeight="bold" size="md" fullWidth className={styles.button} type="submit" rounded analytics={submitAnalytics} data-sentry-element="Button" data-sentry-source-file="FormRecoveryPassword.tsx">
        {submitButtonText}
      </Button>
    </form>;
};
const FormRecoveryPassword = memo(BaseFormRecoveryPassword);
export * from './FormRecoveryPassword.types';
export { FormRecoveryPassword };