import type { FC } from 'react';
import { memo, useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import { generalError, payoutCancel, useMutate } from '@mwl/core-lib';
import { Button } from '@/components/Button/Button';
import { Loader } from '@/components/Loader/Loader';
import { Message } from '@/components/Message/Message';
import { MessageSuccessCancelling } from '../MessageSuccessCancelling/MessageSuccessCancelling';
import { useConfirmationCancellingState } from './MessageConfirmCancelling.hooks';
import type { MessageConfirmCancellingProps } from './MessageConfirmCancelling.types';
import styles from './MessageConfirmCancelling.module.scss';
const BaseMessageConfirmCancelling: FC<MessageConfirmCancellingProps> = ({
  payoutId,
  onClose,
  onSuccess,
  onCancel
}) => {
  const {
    t
  } = useTranslation('payment');
  const {
    form,
    error,
    handleCancellingError,
    handleCancellingSuccess
  } = useConfirmationCancellingState();
  const {
    state: {
      isLoading
    },
    onMutate: onCancelRequest
  } = useMutate({
    requestHandler: payoutCancel,
    onSuccess: () => {
      onSuccess?.();
      handleCancellingSuccess();
    },
    onError: ({
      errorKey
    }) => {
      handleCancellingError(errorKey || generalError);
    }
  });
  const handleCancelPayment = useCallback(() => {
    if (payoutId) {
      onCancelRequest({
        payoutId
      });
    }
  }, [payoutId, onCancelRequest]);
  return <>
      {isLoading && <div className={styles.loader}>
          <Loader />
        </div>}
      {form === 'CANCEL_CONFIRM' && <div className={styles.root}>
          <h3 className={styles.description}>
            {t('confirm_cancelling.title', 'Are you sure you want to cancel the transaction?')}
          </h3>
          <Button onClick={handleCancelPayment} as="button" variant="fulfilled" color="red" size="md" fontSize="xl" fontWeight="bold" fullWidth rounded className={styles.button}>
            {t('confirm_cancelling.continue', 'Continue')}
          </Button>
          <Button as="button" fontSize="xl" fontWeight="bold" color="white" variant="fulfilled" size="md" fullWidth align="center" rounded className={styles.button} onClick={onCancel}>
            {t('confirm_cancelling.cancel', 'Cancel')}
          </Button>
        </div>}
      {form === 'CANCEL_ERROR' && <Message status="error" className={styles.description} buttonProps={{
      as: 'button',
      onClick: onCancel
    }}>
          {Array.isArray(error) ? t(error) : error}
        </Message>}
      {form === 'CANCEL_SUCCESS' && <MessageSuccessCancelling className={styles.description} onClick={onClose} />}
    </>;
};
export const MessageConfirmCancelling = memo(BaseMessageConfirmCancelling);