import type { ChangeEvent, MouseEvent } from 'react';
import { memo, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'next-i18next';
import { handleEventWithAnalytics } from '@mwl/core-lib';
import { getDataCyProps } from '@mwl/marker-tree';
import type { SelectOption } from '@mwl/ui';
import { OptionList, useSelectState } from '@mwl/ui';
import type { DropdownClasses } from '../Dropdown/Dropdown';
import { Dropdown } from '../Dropdown/Dropdown';
import { SelectHeader } from './components/SelectHeader/SelectHeader';
import { NativeSelect } from './NativeSelect/NativeSelect';
import type { SelectProps } from './Select.types';
import styles from './Select.module.scss';
const CustomSelect = <T extends SelectOption,>({
  className,
  placeholder,
  selected,
  options: initOptions,
  numberFormat,
  classes,
  parentRef,
  filter,
  onSelect: onSelectProp,
  disabled,
  label,
  size = 'md',
  headerClasses,
  closeOnClickOutside,
  searchable = false,
  maxDesktopHeight,
  maxMobileHeight,
  ...props
}: SelectProps<T>): JSX.Element => {
  const {
    t
  } = useTranslation('common');
  const {
    isOpen,
    onToggle,
    onChangeSearch,
    onSelect,
    onOpenChange,
    search,
    options
  } = useSelectState({
    selected,
    onSelect: onSelectProp,
    filter,
    initOptions
  });
  const {
    optionList: optionListClassName,
    ...otherClasses
  } = classes ?? {};
  const header = <SelectHeader {...props} readOnly={!searchable} className={otherClasses?.header} classes={headerClasses} isOpen={isOpen} prefix={selected?.prefix} search={searchable ? search : selected?.label || ''} onChange={onChangeSearch} onToggle={onToggle} numberFormat={numberFormat} placeholder={placeholder} disabled={disabled} label={label} size={size} {...getDataCyProps(props)} />;
  const dropdownClasses: DropdownClasses = useMemo(() => ({
    ...otherClasses,
    container: cn(styles.dropdownContainer, otherClasses?.container)
  }), [otherClasses]);
  return <Dropdown customHeader={header} active={isOpen} onToggle={onOpenChange} classes={dropdownClasses} className={cn(styles.root, className)} parentRef={parentRef} disabled={disabled} width="target" mainAxis={false} closeOnClickOutside={closeOnClickOutside} maxDesktopHeight={maxDesktopHeight} maxMobileHeight={maxMobileHeight} data-sentry-element="Dropdown" data-sentry-component="CustomSelect" data-sentry-source-file="Select.tsx">
      <OptionList className={optionListClassName} classes={{
      empty: styles.empty,
      option: styles.option,
      optionPrefix: styles.prefix
    }} selected={selected} options={options} onSelect={onSelect} emptyMessage={t('select.empty', 'No option')} data-sentry-element="OptionList" data-sentry-source-file="Select.tsx" />
    </Dropdown>;
};
const BaseSelect = <T extends SelectOption,>({
  onSelect,
  analytics,
  className,
  adaptive = true,
  colorVariant = 'default',
  headerClasses,
  ...props
}: SelectProps<T>): JSX.Element => {
  const statClassName: string | undefined = analytics?.select?.data?.cls;
  const headerClassesWithVariant = {
    input: cn(headerClasses?.input, styles[`${colorVariant}InputVariant`]),
    button: headerClasses?.button
  };
  const handleOnSelect = (option: T, event?: MouseEvent<HTMLButtonElement> | ChangeEvent<HTMLSelectElement>) => {
    handleEventWithAnalytics(onSelect, analytics?.select?.eventName, {
      ...(analytics?.select?.data || {}),
      cls: event?.currentTarget?.className,
      text: option.label
    })(option);
  };
  if (adaptive && isMobile) {
    return <NativeSelect onSelect={handleOnSelect} className={cn(className, statClassName)} headerClasses={headerClassesWithVariant} {...props} />;
  }
  return <CustomSelect onSelect={handleOnSelect} className={cn(className, statClassName)} colorVariant={colorVariant} headerClasses={headerClassesWithVariant} {...props} data-sentry-element="CustomSelect" data-sentry-component="BaseSelect" data-sentry-source-file="Select.tsx" />;
};
export * from './Select.types';
export const Select = (memo(BaseSelect) as typeof BaseSelect);