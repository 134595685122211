import { createTheme, extendComponent } from '@mwl/ui';

export const theme = createTheme({
  components: {
    FormField: extendComponent('FormField', {
      defaultProps: {
        size: 'md',
      },
    }),
    FlagIcon: extendComponent('FlagIcon', {
      defaultProps: {
        size: 'sm',
      },
    }),
    Accordion: extendComponent('Accordion', {
      defaultProps: {
        radius: 'md',
        variant: 'filled',
      },
    }),
    Switch: extendComponent('Switch', {
      defaultProps: {
        radius: 'lg',
        size: 'md',
      },
    }),
  },
});
