import { byKey, complex, simple } from '@mwl/marker-tree';

export * from './incidents';

export const profileSecuritySchema = complex({
  currentPasswordInput: simple,
  newPasswordInput: simple,
  confirmPasswordInput: simple,
  submitButton: simple,
});

export const editProfileSchema = complex({
  nicknameInput: simple,
  nicknameEditButton: simple,
  nicknameConfirmButton: simple,
  emailInput: simple,
  phoneInput: simple,
  firstNameInput: simple,
  lastNameInput: simple,
  genderSelector: simple,
  documentInput: simple,
  birthSelector: simple,
  countryInput: simple,
  cityInput: simple,
  submitButton: simple,
});

export const profilePageSchema = complex({
  id: simple,
  security: profileSecuritySchema,
  signOutButton: simple,
  edit: editProfileSchema,
  tabs: byKey(simple),
  sections: byKey(simple),
});
