import type { ChangeEventHandler, FC } from 'react';
import { memo, useCallback, useMemo } from 'react';
import { getDataCyProps, omitTestProps } from '@mwl/marker-tree';
import ArrowIcon from '@public/assets/common/icons/arrow.svg';
import { ButtonIcon } from '@/components/ButtonIcon/ButtonIcon';
import type { FormFieldAsInput, FormFieldAsNumberFormat } from '@/components/FormField/FormField';
import { FormField } from '@/components/FormField/FormField';
import type { SelectHeaderProps } from './SelectHeader.types';
import styles from './SelectHeader.module.scss';
const BaseSelectHeader: FC<SelectHeaderProps> = ({
  className,
  prefix,
  disabled,
  isOpen,
  search,
  numberFormat,
  placeholder,
  onChange,
  onToggle,
  label,
  size,
  classes,
  ...props
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(event => onChange(event.target.value), [onChange]);
  const inputProps = useMemo(() => {
    if (numberFormat) {
      return ({
        as: 'numberFormat',
        ...numberFormat,
        numberFormatPrefix: numberFormat?.prefix
      } as FormFieldAsNumberFormat);
    }
    return ({
      as: 'input'
    } as FormFieldAsInput);
  }, [numberFormat]);
  return <div className={cn(styles.root, className, {
    [styles.opened]: isOpen
  })} {...getDataCyProps(props)} data-sentry-component="BaseSelectHeader" data-sentry-source-file="SelectHeader.tsx">
      <FormField {...inputProps} classes={{
      componentWrapper: cn(classes?.input, styles.input, {
        [styles.disabled]: disabled
      })
    }} prefix={prefix} value={search} placeholder={placeholder} onChange={handleChange} disabled={disabled} label={label} size={size} {...omitTestProps(props)} data-sentry-element="FormField" data-sentry-source-file="SelectHeader.tsx" />
      <ButtonIcon as="button" onClick={onToggle} className={cn(classes?.button, styles.button)} type="button" variant="text" aria-label="select toggle" data-sentry-element="ButtonIcon" data-sentry-source-file="SelectHeader.tsx">
        <ArrowIcon className={styles.icon} data-sentry-element="ArrowIcon" data-sentry-source-file="SelectHeader.tsx" />
      </ButtonIcon>
    </div>;
};
const SelectHeader = memo(BaseSelectHeader);
export * from './SelectHeader.types';
export { SelectHeader };