import { complex, simple } from '@mwl/marker-tree';

import { casinoLayoutSchema } from '../casinoLayout';
import { footerSchema } from '../footer';
import { formLoginSchema } from '../formLogin';
import { formRecoveryCodeSchema } from '../formRecoveryCode';
import { formRecoveryLoginSchema } from '../formRecoveryLogin';
import { formRecoveryPasswordSchema } from '../formRecoveryPassword';
import { headerSchema } from '../header';
import { registerSchema } from '../register';
import { userMenuSchema } from '../userMenu';

export const appPageSchema = complex({
  preloader: simple,
  header: headerSchema,
  formLogin: formLoginSchema,
  footer: footerSchema,
  register: registerSchema,
  formRecoveryLogin: formRecoveryLoginSchema,
  formRecoveryCode: formRecoveryCodeSchema,
  formRecoveryPassword: formRecoveryPasswordSchema,
  formRecoverySuccess: simple,
  casinoLayout: casinoLayoutSchema,
  userMenu: userMenuSchema,
});
