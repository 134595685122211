import { complex, simple } from '@mwl/marker-tree';

import { casinoFiltersSchema } from '../casinoFilters';

export const casinoPageSchema = complex({
  casinoFilters: casinoFiltersSchema,
  casinoSearchResults: simple,
  topGames: simple,
  brandedGames: simple,
  crashGames: simple,
  recommended: simple,
  favoriteGames: simple,
  newGames: simple,
  tournamentEventCard: simple,
  topProviders: simple,
  tournamentGames: simple,
  allGames: simple,
  promotions: simple,
});
