import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { payoutCancelSuccessKey } from '@mwl/core-lib';
import { Message } from '@/components/Message/Message';
import type { MessageSuccessCancellingProps } from './MessageSuccessCancelling.types';
export const MessageSuccessCancelling: FC<MessageSuccessCancellingProps> = ({
  className,
  onClick
}) => {
  const {
    t
  } = useTranslation('payment');
  return <Message className={className} textColor="yellow" status="success" buttonProps={{
    as: 'button',
    onClick
  }} data-sentry-element="Message" data-sentry-component="MessageSuccessCancelling" data-sentry-source-file="MessageSuccessCancelling.tsx">
      {t(payoutCancelSuccessKey)}
    </Message>;
};